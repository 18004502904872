<template>
  <div class="container">
    <div class="static-page" style="margin-bottom: 50px;">
      <h1>ДОГОВОР-ОФЕРТА ООО «АЛАМАТ» ОБ ОКАЗАНИИ УСЛУГ</h1>
      <p>&emsp;Настоящий документ является официальным предложением Общества с ограниченной ответственностью «АЛАМАТ»
        (далее –
        «Исполнитель»), реестровый номер РТО 023641 в Едином Федеральном реестре туроператоров, адресованным российским
        юридическим лицам и индивидуальным предпринимателям, зарегистрированным в установленном законом порядке (далее –
        «Заказчик»), заключить договор об оказании услуг на нижеперечисленных условиях. <br>&emsp;В соответствии с
        пунктом 2
        статьи 437 Гражданского Кодекса Российской Федерации настоящий документ является публичной офертой. Настоящий
        договор-оферта на оказание услуг (далее Договор) заключается в особом порядке: путем акцепта Заказчиком
        настоящей публичной оферты, содержащей все существенные условия Договора, без подписания сторонами. Настоящий
        Договор имеет юридическую силу в соответствии со ст. 434 Гражданского Кодекса Российской Федерации и является
        равносильным договору, подписанному сторонами. Настоящий договор является Договором присоединения. <br>&emsp;Фактом,
        подтверждающим полное и безоговорочное принятие изложенных ниже условий, и акцептом настоящей публичной оферты
        является нажатие Заказчиком кнопки «Акцепт», расположенной в конце настоящего Договора. Настоящий Договор между
        Исполнителем и Заказчиком считается заключенным в момент получения Исполнителем Акцепта Заказчика. Акцепт
        считается полученным Исполнителем в момент появления на экране Заказчика всплывающего окна, подтверждающего
        получение Акцепта Заказчика Исполнителем, непосредственно после нажатия Заказчиком кнопки «Акцепт». При
        отсутствии такого всплывающего окна (вследствие технической ошибки) Акцепт Заказчика не считается полученным
        Исполнителем, а настоящий Договор не считается заключенным. Исполнитель и Заказчик далее совместно именуются
        «Стороны», а по отдельности – «Сторона».</p>

      <h1>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h1>
      <p>&emsp;Возвратный тариф - стоимость Услуги, отмена Заказа которой допускается Заказчиком вплоть до Даты
        наступления
        штрафных санкций.
        Дата наступления штрафных санкций - дата, до наступления которой Заказ на Услугу по Возвратному тарифу может
        быть отменен Заказчиком, и после наступления, которой Заказ на Услугу по Возвратному тарифу автоматически
        становится Заказом на Услугу по Невозвратному тарифу. Дата наступления штрафных санкций указывается в Системе до
        и после совершения Заказа. Оформляя Заказ на Услугу по Возвратному тарифу, Заказчик подтверждает свое
        ознакомление и согласие с Датой наступления штрафных санкций.
        <br>&emsp;Заказ - заявка на Услуги, оформляемая Заказчиком в Системе в порядке и на условиях, предусмотренных настоящим
        Договором.
        <br>&emsp;Личный кабинет- подраздел Сайта, позволяющий Заказчику назначать ответственных сотрудников для исполнения
        Заказчиком Договора, знакомиться со статистической информацией, связанной с исполнением Договора, в том числе с
        количеством оформленных Заказов, информацией о сотрудниках Заказчика, сформировавших Заказы, а также включающий
        иные функции, предусмотренные возможностями Личного кабинета.
        Невозвратный тариф - стоимость Услуги, отмена Заказа которой Заказчиком не допускается. В случае отмены
        Заказчиком Заказа на Услугу по Невозвратному тарифу стоимость Услуги возврату Заказчику не подлежит ни при каких
        обстоятельствах. В исключительных случаях (при наличии возможности) Заказ на Услуги по Невозвратному тарифу
        может быть отменен или изменен, при этом порядок и условия такой отмены или изменения обговариваются Сторонами
        отдельно.
        <br>&emsp;Сайт – информационный ресурс в сети Интернет, размещенный по адресу https://partner.ostrovok.ru, а также версии
        и приложения Сайта для мобильных устройств.
        Система - информационно-технологическая система, предназначенная для Заказа Услуг в Интернете в режиме онлайн,
        расположенная по адресу https://www.corp.ostrovok.ru (или иному адресу, сообщенному Заказчику), используемая
        Заказчиком в целях оформления Заказов на Услугу. Услуга - туристический продукт, включающий услуги по размещению
        туриста/иного клиента, их перевозке, а также иные услуги, указанные в п. 1 настоящего Договора, на которые
        Заказчиком оформляется Заказ.</p>

      <br><b>1. ПРЕДМЕТ ДОГОВОРА</b><br>
      <br>1.1. В соответствии с настоящим Договором Исполнитель обязуется передавать Заказчику, а Заказчик - принимать и
      оплачивать Услуги, в состав которых может включаться:
      <br>1.1.1. Бронирование гостиничных номеров в России;
      <br>1.1.2. Оказание гостиничных услуг в России;
      <br>1.1.3. Заказ транспортного обслуживания в России (авиа-, ж/д билеты, перевозка иным видом транспорта);
      <br>1.1.4. Организация туристских программ (в том числе, экскурсионных);
      <br>1.1.5. Прочие виды туристских услуг по
      письменному согласованию сторон.

      <br>

      <br><b>2. ВЗАИМОДЕЙСТВИЕ СТОРОН ПО ДОГОВОРУ</b><br>
      <br>2.1 Исполнитель предоставляет Заказчику логин и пароль для доступа в Личный кабинет посредством отправки
      логина
      и пароля на электронную почту Заказчика. Заказчик может указать несколько сотрудников, уполномоченных на доступ
      в Личный кабинет посредством изменений настроек в Личном кабинете; при этом каждому ответственному сотруднику
      присваивается персональный логин и пароль; внесение данных ответственного сотрудника в Личный кабинет является
      уполномочием Заказчика таких сотрудников действовать от имени Заказчика. Стороны признают действия, совершенные
      с использованием пароля и логина Заказчика и/или ответственных сотрудников Заказчика, действиями Заказчика.
      <br>2.2. Заказчик оформляет Заказы на Услуги посредством Системы на основании представленной в Системе информации
      об
      Услугах, их стоимости и доступности, а также, по согласованию между Сторонами, по телефону и по электронной
      почте. Оформление Заказа на Услуги в Системе Заказчиком означает ознакомление и согласие Заказчика с изложенными
      в Системе правилами и условиями Заказа Услуг, их изменения и отмены.
      <br>2.3. После оформления Заказа Исполнитель направляет на адрес электронной почты Заказчика/его ответственного
      сотрудника подтверждение Заказа на Услуги (ваучер).
      <br>2.4. Изменение Заказа рассматривается как отмена Заказа и совершение нового Заказа. Отмена Заказа
      осуществляется
      Заказчиком посредством Системы на условиях, сообщенных Заказчику при оформлении Заказа. В соответствии с
      изложенными в Системе политиками (правилами) с Заказчика может быть взыскан штраф за отмену Заказа, в том числе
      в случае поздней отмены Заказа.
      <br>2.5. Заказчик обязан предоставлять туристам и/или иным клиентам полную информацию о заказанных Услугах в
      объеме,
      предусмотренном действующим законодательством. Заказчик обязан уточнять и получать у Исполнителя информацию об
      Услугах, на которые Заказчиком оформляется Заказ.
      <br>2.6. Заказчик осуществляет от своего имени реализацию Услуг, предоставленных Исполнителем по настоящему
      Договору, путем заключения с третьими лицами (туристы, иные клиенты) договоров. При этом такие договоры должны
      соответствовать действующему законодательству в области защиты прав потребителей, а также полностью
      информировать туристов и иных клиентов о потребительских свойствах Услуг, правилах оказания Услуг, условий их
      изменения и отмены.
      <br>2.7. Исполнитель имеет право производить замену Услуг (в т.ч. компанию – перевозчика, отель проживания),
      предоставляемых туристу и иному клиенту с сохранением класса Услуг по ранее оплаченной категории или с
      предоставлением Услуг более высокого класса без дополнительной оплаты.

      <br>

      <br><b>3. СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ</b><br>
      <br>3.1. Стоимость Услуг указывается в Системе в российских рублях.
      <br>3.2. Заказчик оплачивает стоимость Услуг в российских рублях в следующем порядке:
      <br>3.2.1. за совершенный в Системе Заказ на Услуги по Невозвратному тарифу – в течение 5 (пяти) рабочих дней с
      даты
      оформления такого Заказа;
      <br>3.2.2. за совершенный в Системе Заказ на Услуги по Возвратному тарифу – не позднее Даты наступления штрафных
      санкций. Расчеты между Сторонами производятся в российских рублях в безналичной форме путем перечисления
      денежных средств на расчетный счет Исполнителя.
      <br>3.3. Стоимость Услуг не включает в себя стоимость расходов, которые может понести турист/иной клиент в связи с
      оказанием Услуг (в частности, телефонные расходы, обслуживание в гостинице и прочие понесенные издержки, которые
      не были включены в подтверждение, переданное Исполнителем при оформлении Заказа); турист/иной клиент несет
      единоличную ответственность за оплату таких дополнительных расходов.
      <br>3.4. Услуги Исполнителя по оформленному
      Заказу считаются оказанными в момент окончания оказания Услуг туристу/иному клиенту, с которым Заказчиком был
      заключен договор о реализации таких Услуг.
      <br>3.5. В течение 10 (десяти) рабочих дней с даты окончания, отчетного календарного месяца Исполнитель
      посредством
      Системы формирует Универсальный передаточный документ по форме, утвержденной Письмом ФНС России от 21.10.2013
      №ММВ-20-3-96 (далее «УПД»). По требованию Заказчика, выраженного путем проставления соответствующей отметки в
      Личном кабинете, Исполнитель выставляет и направляет Заказчику отчетные документы на бумажном носителе.
      <br>3.6. Заказчик обязан в течение 5 (пяти) рабочих дней с даты формирования Исполнителем УПД направить
      Исполнителю
      2 экземпляра подписанного УПД или направить Исполнителю письменный мотивированный отказ от подписания УПД. Если
      в указанный срок Заказчик не представит Исполнителю мотивированных возражений на подписание УПД и/или не
      подпишет УПД, услуги по настоящему Договору будут считаться оказанными Исполнителем в полном объеме и надлежащим
      образом.
      <br>3.7. Датой оплаты Услуг считается день зачисления денежных средств на расчетный счет Исполнителя.
      <br>3.8. Все расчеты между Сторонами по настоящему Договору осуществляются в российских рублях.

      <br>

      <br><b>4. ГАРАНТИИ ИСПОЛНЕНИЯ ОБЯЗАТЕЛЬСТВ</b><br>
      <br>4.1. В целях обеспечения надлежащего исполнения Заказчиком денежных обязательств по настоящему Договору
      Исполнитель вправе в одностороннем порядке устанавливать в Личном кабинете предельный размер неоплаченной
      задолженности Заказчика перед Исполнителем (далее – Лимит). По достижении Заказчиком Лимита возможность
      оформления Заказов приостанавливается вплоть до полного погашения Заказчиком задолженности.
      <br>4.2. В рамках Лимита учитывается задолженность Заказчика по оплате оформленных в Системе Заказов по
      Невозвратному тарифу. При этом при наступлении Даты наступления штрафных санкций по Заказам на Услуги по
      Возвратным тарифам и неисполнении Заказчиком обязательства, предусмотренного п. 3.2.2. настоящего Договора,
      задолженность по Возвратному тарифу автоматически превращается в задолженность по Невозвратному тарифу и
      учитывается в составе Лимита.
      <br>4.3. При нарушении Заказчиком условий, предусмотренных настоящим пунктом, (превышении указанного Лимита)
      доступ
      Заказчика к оформлению Заказов приостанавливается Системой. Возобновление указанного в настоящем пункте доступа
      Заказчика, а равно и пользование Системой возможно только в случае, когда Заказчик полностью оплатит
      образовавшуюся задолженность перед Исполнителем. Исполнитель вправе также отменить неоплаченные Заказы на
      Услуги.
      <br>4.4. Исходя из своевременности и полноты оплаты Заказчиком услуг по Договору, размер Лимита может быть изменен
      Исполнителем как в сторону уменьшения, так и в сторону увеличения, путем публикации соответствующей информации в
      Личном кабинете и/или информирования Заказчика по электронной почте.

      <br>

      <br><b>5. ОСОБЫЕ УСЛОВИЯ</b><br>
      <br>5.1. В информации, программном обеспечении, продуктах и услугах, опубликованных в Системе, могут встречаться
      неточности или опечатки. В частности, Исполнитель не гарантирует точности фотографий, относящихся к гостиницам,
      а также описаний, списков услуг гостиниц и другой описательной информации, поскольку значительная часть этой
      информации предоставлена соответствующими поставщиками, и Исполнитель не может нести ответственность за любые
      неточности.
      <br>5.2. Позднее аннулирование или изменение Заказа или неприбытие туриста или иного клиента Заказчика к месту и
      времени начала оказания заказанных в Системе Услуг, влекут за собой выплату Заказчиком в пользу Исполнителя
      штрафа в размере стоимости заказанных в Системе Услуг, приходящихся на первые сутки обслуживания. В некоторых
      случаях, согласно опубликованным в Системе условиям оформления Заказа, штрафные санкции могут увеличиваться до
      полной стоимости заказанных Услуг на весь период. 5.3. Исполнитель не несет ответственность по возмещению
      денежных затрат Заказчику, туристу/иному клиенту за оплаченные Услуги, если турист/иной клиент в период
      обслуживания по своему усмотрению или в связи со своими интересами не воспользовался всеми или частью Услуг, и
      не возмещает расходы, выходящие за рамки оговоренных в настоящем Договоре.
      <br>5.4. В случае аннуляции оплаченного Заказа в определенный условиями Системы период действия штрафных санкций
      возврат средств, оплаченных Заказчиком Исполнителю, производится за вычетом суммы штрафных санкций, указанных в
      Системе и подтверждении Заказа (ваучере).
      <br>5.5. В случае отмены Заказчиком Заказа на Услуги, по условиям которого стоимость Услуг подлежит возврату
      Заказчику полностью или частично, денежные средства за такой отмененный Заказ аккумулируются и учитываются в
      Системе, и могут быть засчитаны в счет оформления и оплаты будущих Заказов. Денежные средства за отмененные
      Заказы также могут быть перечислены Заказчику по его письменному заявлению в течение 5 (пяти) рабочих дней с
      момента уведомления об этом Исполнителя.
      <br>5.6. Заказы на групповые Услуги.
      <br>5.7. При возникновении при оформлении Заказчиком Заказа любых ошибок, связанных с невозможностью завершения
      процедуры оформления Заказа, Заказчик имеет право воспользоваться опцией «автоматического оформления Заказа», в
      соответствии с которой в течение заданного периода времени Система по заданию Заказчика автоматически пытается
      завершить оформление Заказа, не оконченного Заказчиком вследствие ошибок. Для выбора данной опции Заказчику
      необходимо поставить галочку рядом с надписью «автоматическое оформление Заказа», а также определить необходимые
      параметры «автоматического оформления Заказа», например, такие как: заданное время для «автоматического
      оформления Заказа», предельная стоимость Услуг, Заказ на которые может быть оформлен в рамках «автоматического
      оформления Заказа», иные условия (при наличии). Если в течение заданного времени «автоматического оформления
      Заказа» Заказ будет успешно оформлен, наступают последствия, предусмотренные п. 2.3. настоящего Договора.
      Стороны согласны, что «автоматическое оформление Заказа» может быть, как успешно завершено, так и может не
      состояться, с одинаковой долей вероятности. Исполнитель не несет никакой ответственности за невозможность
      «автоматического оформления Заказа» по любым обстоятельствам.
      <br>5.8. Исполнитель вправе аннулировать Заказы на Услуги при наличии неоплаченной Заказчиком задолженности по
      иным
      Заказам, уведомив об этом Заказчика по электронной почте. Заказчик принимает на себя все негативные последствия
      неисполнения обязанностей по оплате Услуг, в том числе возмещает Исполнителю все понесенные им расходы в связи с
      аннуляцией Заказа на Услуги, включая выплату штрафных санкций.

      <br>

      <br><b>6. ОТВЕТСТВЕННОСТЬ</b><br>
      <br>6.1. За неисполнение или ненадлежащее исполнение обязательств по настоящему Договору Стороны несут
      ответственность, предусмотренную настоящим Договором и действующим законодательством РФ.
      <br>6.2. За просрочку Заказчиком оплаты оформленных по Заказам Услуг в сроки, установленные настоящим Договором,
      Исполнитель оставляет за собой право начислять Заказчику пени в размере 0,3% (ноль целых три десятых процента) с
      просроченной суммы за каждый календарный день просрочки. Уплата пени не освобождает Заказчика от исполнения
      обязательств по оплате.
      <br>6.3. Исполнитель не несет ответственности перед Заказчиком, туристом/иным клиентом в случае невозможности
      осуществления принятых на себя обязательств, вследствие недостоверности, недостаточности и/или несвоевременности
      сведений и документов, представленных Заказчиком.
      <br>6.4. Ответственность Исполнителя может наступать лишь при условии поступления полной суммы за все заказанные и
      подтвержденные Услуги.
      <br>6.5. Исполнитель не несет ответственности:
      • за отсутствие у туриста документов, необходимых для потребления Услуг; • за опоздание туриста к месту сбора
      группы, отправления поезда, вылета самолета в назначенное время;
      • за прохождение туристом паспортного, пограничного и таможенного контроля;
      • в случае задержки или отмены рейса по вине перевозчика;
      • в случае утраты или кражи вещей туриста.
      <br>6.6. Исполнитель не несет ответственность за ущерб, нанесенный туристом/иным клиентом, третьей стороне или
      ущерб, нанесенный туристу/иному клиенту третьей стороной.
      <br>6.7. Стороны ни при каком условии не возмещают друг другу упущенную выгоду.

      <br>

      <br><b>7. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ</b><br>
      <br>7.1. Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по
      Договору, если докажут, что это было вызвано возникновением обстоятельств непреодолимой силы (война, стихийные
      бедствия, решения органов государственной власти и управления, а также другие обстоятельства, не зависящие от
      воли сторон и не поддающиеся их контролю).
      <br>7.2. Стороны обязаны уведомлять друг друга о возникновении обстоятельств непреодолимой силы не позднее 3
      (трех)
      рабочих дней с того момента, когда информирующая сторона узнала об их возникновении. Такие уведомления
      направляются сторонами посредством факсимильной, электронной или иной связи, позволяющей зафиксировать факт
      отправки и получения информации (документов) сторонами.
      <br>7.3. Если действие обстоятельств непреодолимой силы будет продолжаться более одного месяца, стороны вправе
      принять (без предъявления взаимных претензий) решение о прекращении действия Договора, либо о приостановлении
      его действия.

      <br>

      <br><b>8. ИНФОРМАЦИЯ</b><br>
      <br>8.1. Стороны обязуются принимать все необходимые и достаточные меры, направленные на защиту персональных
      данных
      физических лиц в соответствии с требованиями, установленными Федеральным законом от 27.07.2006 № 152-ФЗ «О
      персональных данных». Стороны обязуются осуществлять обработку персональных данных физических лиц в строгом
      соответствии с установленными законом принципами и правилами. Передаваемые друг другу Сторонами персональные
      данные физических лиц относятся к конфиденциальной информации. Стороны обязуются использовать персональные
      данные физических лиц исключительно для целей, необходимых для исполнения Договора. Обрабатываемые персональные
      данные физических лиц подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты
      необходимости в достижении этих целей.
      <br>8.2. Стороны обязуются соблюдать конфиденциальный характер коммерческой и финансовой информации, полученной от
      другой Стороны в течение срока действия Договора, а также в течение 2 (двух) лет после его прекращения по любым
      обстоятельствам.
      <br>8.3. Стороны обязуются не разглашать без предварительного письменного согласия другой Стороны любую информацию
      конфиденциального характера, ставшую известной в ходе исполнения настоящего Договора, не передавать третьим
      лицам и не использовать иначе, чем для выполнения своих договорных обязательств.
      <br>8.4. Стороны не несут ответственности за разглашение информации, если она не была определена как
      конфиденциальная, после ее передачи третьим лицам, либо публичного распространения.
      <br>8.5. Сторона, виновная в нарушении условий о конфиденциальности, возмещает другой Стороне все понесенные в
      связи
      с этим убытки в полном объеме.
      <br>8.6. Заказчик не вправе использовать любые коммерческие обозначения (товарные знаки), как принадлежащие
      Исполнителю так и находящиеся в его управлении любым образом, в рекламных или любых иных целях без
      предварительного письменного согласия Исполнителя.
      <br>8.7. Заказчик согласен на использование его наименования и/или товарного знака (логотипа) в маркетинговой
      деятельности Исполнителя в течение срока действия Договора.

      <br>

      <br><b>9. СРОК ДЕЙСТВИЯ, ИЗМЕНЕНИЕ, РАСТОРЖЕНИЕ ДОГОВОРА</b><br>
      <br>9.1. Настоящий Договор вступает в силу с момента получения Исполнителем Акцепта Заказчика и заключен на 1
      (один)
      календарный год. Договор подлежит ежегодной автоматической пролонгации на следующий календарный год, если ни
      одна из Сторон не позднее, чем за месяц до истечения срока действия Договора, письменно не заявит другой Стороне
      о своем желании расторгнуть Договор. При этом количество пролонгаций Договора не ограничено.
      <br>9.2. Каждая из Сторон имеет право отказаться от исполнения настоящего Договора в одностороннем порядке при
      условии направления другой Стороне письменного уведомления за 30 (тридцать) календарных дней до даты такого
      отказа.
      <br>9.3. В случае прекращения Договора независимо от причин прекращения обязательства Сторон, возникшие до даты
      прекращения Договора (в том числе, обязательства по уплате штрафных санкций), должны быть исполнены Сторонами в
      полном объеме. При прекращении настоящего Договора Заказчик в 10-дневный календарный срок с момента прекращения
      Договора перечисляет в адрес Исполнителя все причитающиеся ему денежные средства, а также предоставляет
      необходимые отчетные документы. Стороны также в вышеуказанный срок проводят сверку задолженности и производят
      взаиморасчеты.

      <br>

      <br><b>10. РАЗРЕШЕНИЕ СПОРОВ</b><br>
      <br>10.1. Все споры, связанные с исполнением/неисполнением настоящего Договора, подлежат разрешению Сторонами
      путем
      переговоров и в претензионном порядке. Претензионный досудебный порядок разрешения споров является обязательным.
      Несоблюдение Заказчиком претензионного порядка, предусмотренного условиями настоящего Договора (в том числе
      несвоевременное уведомление клиентами Заказчика представителя принимающей стороны о факте неоказания или
      ненадлежащего оказания Услуг) является основанием для отказа в удовлетворении претензий Заказчика. Обязанность
      по доказыванию факта своевременного уведомления представителя принимающей стороны и (или) факта вручения
      претензии Исполнителю возложена на Заказчика. Надлежащим доказательством факта вручения претензии является
      уведомление о вручении письма с претензией, подписанное уполномоченным представителем Исполнителя, либо отметка
      о получении претензии, проставленная уполномоченным представителем Исполнителя на копии претензии.
      <br>10.2. При условии наличия у Заказчика письменного заявления туриста и/или иного клиента с претензиями на
      качество обслуживания Заказчик имеет право подать претензию, которая принимается Исполнителем в течение 20
      (двадцати) календарных дней со дня окончания обслуживания туриста и/или иного клиента
      <br>10.3. Претензия подается Заказчиком в письменном виде с приложением заявления туриста и/или иного клиента,
      письменных доказательств обоснованности его требований, договора о реализации туристского продукта и иных
      документов, имеющих отношение к делу. Исполнитель рассматривает полученную претензию в течение 10 (десяти) дней
      с момента получения. В случае необходимости в проведении служебного расследования, переговоров с Заказчиком и
      иных мероприятий в целях выяснения всех обстоятельств дела срок рассмотрения претензии может быть увеличен. В
      любом случае размер убытков, подлежащих возмещению Заказчику Исполнителем, не может превышать стоимости
      оплаченных, но не предоставленных Услуг.
      <br>10.4. Споры, которые не могут быть разрешены Сторонами путем переговоров и в претензионном порядке, подлежат
      разрешению в Арбитражном суде г. Москвы в соответствии с действующим законодательством РФ.

      <br>

      <br><b>11. ИНЫЕ УСЛОВИЯ</b><br>
      <br>11.1. Стороны обязаны в письменном виде информировать друг друга об изменении своих адресов и иных реквизитов
      не
      позднее 5 (пяти) рабочих дней с даты, такого изменения. Любые сообщения, направленные по последнему известному
      Стороне адресу, будут считаться надлежаще отправленными.
      <br>11.2. Ни одна из Сторон не вправе уступать свои права или обязанности по Договору третьей стороне полностью
      или
      в части без предварительного письменного согласия другой Стороны, за исключением права Исполнителя в
      соответствии со ст. 392.3 ГК РФ осуществить передачу Договора своему аффилированному лицу, с обязательным
      последующим уведомлением Заказчика, которое может быть сделано, в том числе путем опубликования информации о
      передаче Договора в Системе. В случае уступки требования по Договору третьим лицам без предварительного
      письменного согласия Исполнителя Заказчик уплачивает Исполнителю штраф в размере 20% от суммы Услуг, оказанных
      Исполнителем, за весь срок действия Договора.
      <br>11.3. Исполнитель вправе внести изменения в настоящий Договор в
      одностороннем порядке (включая, но не ограничиваясь условиями об изменении размера и условий гарантий исполнения
      обязательства, изменении условий (сроков) оплаты Услуг, открытии/закрытии Заказчику возможности оформлять Заказы
      на Услуги по Невозвратному тарифу) с обязательным опубликованием соответствующих изменений в Системе не позднее
      10 (десяти) календарных дней до даты вступления соответствующих изменений в силу. В случае если Заказчик не
      согласен с внесенными в Договор изменениями, Заказчик обязан письменно уведомить об этом Исполнителя не позднее
      даты вступления изменений в силу; в этом случае Договор считается расторгнутым по соглашению Сторон в дату
      вступления изменений в силу. В противном случае, отношения Сторон регулируются условиями Договора с учетом
      внесенных изменений.
      <br>11.4. Стороны договорились считать переписку по электронной почте, осуществленную между Сторонами посредством
      адресов корпоративной электронной почты с доменными именами, указанными в реквизитах Договора, или посредством
      адресов электронной почты, которые полностью указаны в реквизитах Договора, официальной и учитывать при решении
      возможных претензий/споров, возникших из или в связи с исполнением Договора. Стороны договорились считать такую
      переписку, а также любые документы, полученные посредством факсимильной, электронной или иной связи, в том числе
      с использованием информационно-телекоммуникационной сети «Интернет», надлежащим и допустимым доказательством по
      делу в случае возникновения между Сторонами судебных споров. При этом наличие электронной цифровой подписи на
      указанных выше документах не является обязательным условием признания таких документов надлежащим и допустимым
      доказательством по делу.
      <br>11.5. Договор регулируется и толкуется в соответствии с действующим законодательством Российской Федерации.
      Используемые в настоящем Договоре иные основные понятия (термины) толкуются в соответствии с Федеральным законом
      от 24.11.1996 № 132-ФЗ «Об основах туристской деятельности в Российской Федерации».
      <br>11.6. Во всем остальном, что не предусмотрено настоящим Договором, Стороны руководствуются действующим
      законодательством РФ(Российской Федерации).
      <br>11.7. Финансовым обеспечением Исполнителя является Договор страхования гражданской ответственности за
      неисполнение либо ненадлежащее исполнение обязательств по договору о реализации Туристского продукта №
      00336-420001-22 от «29» апреля 2022 года. Срок действия указанного договора: с «10» мая 2022 по «09» мая 2023
      гг. (далее по тексту – Договор страхования). Размер Финансового обеспечения составляет 500 000 (Пятьсот тысяч)
      рублей. Финансовое обеспечение предоставлено Исполнителю АО «Боровицкое страховое общество», находящимся по
      адресу: 101000, г. Москва, бульвар Покровский, дом 4/17, корпус 3 (далее по тексту – Страховщик). Страховщик
      обязан выплатить страховое возмещение по Договору страхования по письменному требованию туриста и (или) иного
      клиента при наступлении страхового случая. Основанием для выплаты страхового возмещения по Договору страхования
      является факт установления обязанности Исполнителя возместить туристу и (или) иному клиенту реальный ущерб,
      возникший в результате неисполнения или ненадлежащего исполнения Исполнителем обязательств по договору о
      реализации туристского продукта, если это является существенным нарушением условий такого договора. Действие
      настоящего пункта распространяется исключительно на ответственность Исполнителя перед туристом по договору о
      реализации туристского продукта (комплекса услуг по перевозке и размещению, оказываемых за общую цену),
      заключенному Заказчиком с туристом от имени Исполнителя на основании выданной Исполнителем доверенности.
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  async mounted() {

  },
  methods: {}
}
</script>
